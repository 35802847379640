// General
body {
  font-family: 'Aileron-Light', sans-serif;
  font-size: 14px; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Aileron-Bold', sans-serif; }

a {
  font-family: 'Aileron-Light', sans-serif;
  -webkit-transition: all .3s ease !important;
  -o-transition: all .3s ease !important;
  transition: all .3s ease !important; }
.btn {
  -webkit-transition: all .3s ease !important;
  -o-transition: all .3s ease !important;
  transition: all .3s ease !important; }

.table {
  th, td {
    font-family: 'Aileron-Light', sans-serif; } }

input[type="text"], input[type="email"], input[type="password"], select, textarea {
  font-family: 'Aileron-Light', sans-serif;
  font-size: 14px; }

ul {
  list-style-type: none;
  padding: 0;
  margin: 0; }

.form-control {
  background: #ffffff;
  border-color: #D3D4D4;
  border-radius: 0;
  color: #4D4D4D;
  height: 30px;
  font-family: 'Aileron-Light', sans-serif;
  font-size: 14px; }

textarea.form-control {
  height: auto; }

.container {
  max-width: 1190px; }

@media (min-width: 576px) {
  .container-boxed {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container-boxed {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container-boxed {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container-boxed {
    max-width: 1140px; } }

.container-boxed {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1230px; }

.gutters-10 {
  margin-right: -10px;
  margin-left: -10px; }

.gutters-10>.col,
.gutters-10>[class*=col-] {
  padding-right: 10px;
  padding-left: 10px; }

.gutters-15 {
  margin-right: -15px;
  margin-left: -15px; }

.gutters-15>.col,
.gutters-15>[class*=col-] {
  padding-right: 15px;
  padding-left: 15px; }

// Header
.header {
  .header-top {
    padding: 34px 0;
    .widgets {
      ul {
        li {
          display: inline-block;
          height: 28px;
          padding: 0 23px;
          &:last-child {
            padding-right: 0; }
          & + li {
            border-left: 1px solid #E6E6E6; }
          a {
            font-size: 15px;
            color: #008CF6;
            text-decoration: none;
            line-height: 29px;
            display: inline-block;
            &:hover {
              color: #001E98; }
            i {
              display: inline-block;
              width: 29px;
              height: 26px;
              vertical-align: middle;
              margin-right: 3px;
              &.icon-precaution {
                background: url('../img/icon-precaution.png') no-repeat; }
              &.icon-doc {
                background: url('../img/icon-doc.png') no-repeat; }
              &.icon-email {
                background: url('../img/icon-email.png') no-repeat; } } } } } } } }

.navbar-main {
  padding: 0;
  background: #E9EFFF;
  .navbar-toggler {
    position: absolute;
    top: -50px;
    right: 20px;
    color: #001E98;
    .fa {
      color: #001E98; } }
  .nav-item {
    & + .nav-item {
      margin-left: 30px; }
    .nav-link {
      color: #001E98;
      font-size: 13px;
      text-decoration: none;
      line-height: 51px;
      padding: 0 25px;
      font-family: 'Aileron-Bold', sans-serif;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background: #E9EFFF; }
      &:hover {
        color: #008CF6; } }
    &.active {
      .nav-link {
        color: #008CF6;
        background: #fff;
        &:before {
          background-color: #008CF6; } } } }
  .dropdown-menu {
    background: #001E98;
    padding: 0;
    .dropdown-item {
      border-bottom: 1px solid #2D5AB3;
      padding: 10px 18px;
      color: #fff;
      &:last-child {
        border-bottom: 0; }
      &:hover {
        color: #00CAFF;
        background: #001E98; }
      .fa {
        margin-left: 5px;
        line-height: 22px;
        vertical-align: bottom; } } } }

.menu-large {
  position: static !important; }
.megamenu {
  padding: 20px 0px;
  width: 100%;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  margin: 0;
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      color: #000000;
      font-family: 'Gotham Rounded Book';
      font-size: 11px;
      &.title ~ .title {
        margin-top: 20px; }
      a {
        display: block;
        clear: both;
        font-weight: normal;
        font-size: 11px;
        line-height: 20px;
        color: #818181;
        white-space: normal;
        font-family: 'Gotham Rounded Light';
        &.news {
          text-align: center;
          font-family: 'Gotham Rounded Book';
          font-size: 14px;
          max-width: 200px;
          color: #000000; }
        span {
          display: block;
          padding: 10px 0; } } } } }

.dropdown-menu {
  background: #fff;
  border: 0;
  border-radius: 0;
  padding: 20px;
  &.show {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out; } }

@media (max-width: 768px) {
  .megamenu {
    margin-left: 0;
    margin-right: 0;
    li {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0; } } }
  .open .dropdown-menu {
    color: #fff; } }

.fade-carousel {
  position: relative;
  .carousel-indicators > li {
    margin: 0 2px;
    background-color: #f39c12;
    border-color: #f39c12;
    opacity: .7;
    &.active {
      width: 10px;
      height: 10px;
      opacity: 1; } }
  .carousel-inner {
    .carousel-caption {
      top: 50%;
      left: 50%;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.75);
      -webkit-transform: translate3d(-50%, -50%, 0);
      -moz-transform: translate3d(-50%, -50%, 0);
      -ms-transform: translate3d(-50%, -50%, 0);
      -o-transform: translate3d(-50%, -50%, 0);
      transform: translate3d(-50%, -50%, 0);
      h1 {
        font-size: 200%;
        font-weight: 800;
        margin: 0;
        padding: 0; }
      opacity: 0;
      -webkit-transition: 2s all ease-in-out .1s;
      -moz-transition: 2s all ease-in-out .1s;
      -ms-transition: 2s all ease-in-out .1s;
      -o-transition: 2s all ease-in-out .1s;
      transition: 2s all ease-in-out .1s; }
    .active .carousel-caption {
      opacity: 1;
      -webkit-transition: 2s all ease-in-out .1s;
      -moz-transition: 2s all ease-in-out .1s;
      -ms-transition: 2s all ease-in-out .1s;
      -o-transition: 2s all ease-in-out .1s;
      transition: 2s all ease-in-out .1s; } } }

.main-slider {
  .carousel-control-next, .carousel-control-prev {
    width: 5%; }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 60px;
    height: 60px;
    background-color: #D9DA5E;
    background-size: 16px 28px;
    display: none; }
  .carousel-control-next-icon {
    background-image: url('../img/slider/arrow-next.png'); }
  .carousel-control-prev-icon {
    background-image: url('../img/slider/arrow-prev.png'); }
  .carousel-item {
    text-align: center;
    img {
      height: auto;
      max-height: 537px;
      width: 100%; } }
  .carousel-caption {
    bottom: 120px;
    text-align: center;
    right: 0;
    left: 0;
    max-width: 608px;
    margin: 0 auto;
    span {
      display: block;
      font-size: 20px;
      color: #3C3554;
      margin-bottom: 15px; }
    h1 {
      display: inline-block;
      width: 100%;
      font-size: 55px;
      font-family: 'Aileron-Heavy', sans-serif;
      color: #fff;
      text-shadow: 0px 0px 6px rgba(0, 0, 0, 1);
      border-bottom: 1px solid #fff;
      padding-bottom: 30px;
      margin-bottom: 0; }
    h2 {
      display: inline-block;
      max-width: 380px;
      font-size: 36px;
      margin-bottom: 30px;
      line-height: 40px;
      color: #000; }
    h3 {
      display: inline-block;
      max-width: 380px;
      font-size: 25px;
      margin-bottom: 30px;
      line-height: 40px;
      color: #fff; }
    .text-caption {
      position: relative;
      padding: 36px 0 30px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 202px;
        height: 4px;
        background: #fff;
        margin-left: -101px; }
      span {
        display: block;
        margin-bottom: 0;
        &.text1 {
          font-size: 20px;
          color: #fff;
          font-family: 'Aileron-Bold', sans-serif; } } }
    .btn-caption {
      color: #ffffff;
      border: 0;
      background: #615475;
      width: 226px;
      height: 32px;
      font-size: 17px;
      font-family: 'Raleway', sans-serif;
      border-radius: 0;
      line-height: 32px;
      padding: 0 10px;
      margin-top: 22px;
      &:hover {
        border-color: #000;
        background: #000;
        color: #fff; } }
    .btn-caption2 {
      color: #ffffff;
      border: 2px solid #fff;
      width: 170px;
      height: 45px;
      font-size: 16px;
      font-family: 'Gotham Rounded Book';
      border-radius: 26px;
      padding: .5rem 50px;
      margin: 0 14px;
      &:hover {
        border-color: #ffffff;
        background: #ffffff;
        color: #000; } } }
  .carousel-indicators {
    li {
      width: 18px;
      height: 18px;
      background-color: transparent;
      border: 1px solid #fff;
      border-radius: 9px;
      cursor: pointer;
      &.active {
        background-color: #fff; } } } }


// Home

.section {
  .content {
    max-width: 922px;
    margin-left: auto;
    margin-right: auto; } }

.concession-home {
  .concession-content {
    padding-top: 99px;
    padding-bottom: 108px;
    h2 {
      font-size: 35px;
      color: #001E98; }
    p {
      color: #576380;
      font-size: 17px; }
    .concession-image {
      position: relative;
      span {
        position: absolute;
        width: 34px;
        height: 34px;
        z-index: 444;
        &.top {
          top: -34px;
          right: -34px;
          background: #545DD6; }
        &.bottom {
          bottom: -17px;
          left: -17px;
          background-color: #00CAFF; } }
      &:before {
        content: '';
        position: absolute;
        top: -14px;
        right: -16px;
        width: 70px;
        height: 70px;
        border: 1px solid #00AAF7;
        z-index: 555; } } } }

@media (min-width: 992px) {
  .col-left {
    -ms-flex: 0 0 57%;
    flex: 0 0 57%;
    max-width: 57%; }
  .col-right {
    -ms-flex: 0 0 43%;
    flex: 0 0 43%;
    max-width: 43%; } }

.section-title {
  text-align: center;
  h2 {
    font-size: 35px;
    color: #fff; }
  h3 {
    font-size: 21px;
    color: #fff;
    font-family: 'Aileron-Light', sans-serif;
    margin-bottom: 50px; } }

.technique-file {
  background: #6179EC;
  padding: 90px 0 78px;
  .content {
    max-width: 914px;
    border-top: 1px solid #00C9FD;
    padding: 42px 0 0;
    p {
      color: #fff;
      font-size: 16px;
      margin-bottom: 5px;
      &.blue {
        color: #00CAFF;
        text-align: right; } }
    .feature {
      padding-top: 50px;
      .feature-box {
        background: #596CDB;
        border-bottom: 1px solid #00C9FD;
        text-align: center;
        padding: 49px 25px 38px;
        margin-bottom: 32px;
        .icon {
          height: 70px;
          line-height: 70px;
          vertical-align: bottom;
          display: inline-block; }
        .caption {
          padding-top: 17px;
          h4 {
            color: #fff;
            font-weight: 900;
            font-size: 16px;
            margin-bottom: 14px; }
          p {
            color: #fff;
            font-size: 16px;
            line-height: 21px; } } } } } }

.home-news {
  padding: 102px 0 47px;
  .content {
    max-width: 918px; }
  .news-item {
    border: 0;
    border-radius: 0;
    margin-bottom: 30px;
    .card-image {
      border: 3px solid #E6E6E6;
      padding: 8px 8px 29px 8px; }
    .card-caption {
      text-align: center;
      h4 {
        font-size: 22px;
        color: #001E98;
        a {
          color: #001E98;
          font-family: 'Aileron-Bold', sans-serif;
          display: inline-block;
          width: 226px;
          height: 52px;
          background: #fff;
          margin-top: -20px;
          text-decoration: none; } }
      p {
        color: #576380;
        font-size: 14px;
        line-height: 22px; } } } }

.partners {
  padding: 58px 0 45px;
  border-top: 1px solid #E6E6E6;
  .partners-list {
    display: table;
    width: 100%;
    li {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      width: 27%;
      &:last-child {
        text-align: right;
        width: 19%; } } }
  .owl-carousel {
    .owl-item {
      text-align: center;
      img {
        width: auto !important;
        display: inline-block; } } } }


.footer {
  background: #001E98;
  padding: 23px 0;
  .copy {
    color: #fff;
    line-height: 37px;
    margin-bottom: 0; }
  .footer-nav {
    text-align: right;
    ul {
      li {
        display: inline-block; } }
    .nav-link {
      color: #00CAFF;
      font-size: 14px;
      &:hover {
        color: #fff; } } } }

.about {
  .about-heading {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#575dd2+0,7a95ff+50,7a95ff+50 */
    background: #575dd2 /* Old browsers */;
    background: -moz-linear-gradient(left, #575dd2 0%, #7a95ff 50%, #7a95ff 50%) /* FF3.6-15 */;
    background: -webkit-linear-gradient(left, #575dd2 0%,#7a95ff 50%,#7a95ff 50%) /* Chrome10-25,Safari5.1-6 */;
    background: linear-gradient(to right, #575dd2 0%,#7a95ff 50%,#7a95ff 50%) /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#575dd2', endColorstr='#7a95ff',GradientType=1 ) /* IE6-9 */;
    .container-fluid {
      padding-left: 0;
      padding-right: 0; } }
  .about-box {
    width: 100%;
    max-width: 580px;
    float: right;
    padding: 60px 100px 60px 60px;
    height: 100%;
    h1 {
      color: #fff;
      font-size: 35px;
      margin-bottom: 25px; }
    p {
      font-size: 17px;
      line-height: 23px;
      color: #fff; } }
  .about-content {
    width: 100%;
    max-width: 790px;
    margin-left: auto;
    margin-right: auto;
    .intro {
      padding: 80px 0 54px;
      text-align: center;
      p {
        font-size: 16px;
        color: #576380; } }
    .image {
      position: relative;
      span {
        position: absolute;
        width: 34px;
        height: 34px;
        z-index: 444; }
      span.bottom {
        bottom: -17px;
        left: -17px;
        background-color: #00CAFF; }
      span.top {
        top: -34px;
        right: -34px;
        background: #545DD6; }
      &.top-square {
        &:before {
          content: '';
          position: absolute;
          top: -14px;
          right: -16px;
          width: 70px;
          height: 70px;
          border: 1px solid #00AAF7;
          z-index: 555; } } } }

  .data-table {
    margin-top: 72px;
    margin-bottom: 95px; } }

.concession {
  .concession-heading {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#575dd2+0,7a95ff+50,7a95ff+50 */
    background: #575dd2 /* Old browsers */;
    background: -moz-linear-gradient(left, #575dd2 0%, #7a95ff 50%, #7a95ff 50%) /* FF3.6-15 */;
    background: -webkit-linear-gradient(left, #575dd2 0%,#7a95ff 50%,#7a95ff 50%) /* Chrome10-25,Safari5.1-6 */;
    background: linear-gradient(to right, #575dd2 0%,#7a95ff 50%,#7a95ff 50%) /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#575dd2', endColorstr='#7a95ff',GradientType=1 ) /* IE6-9 */;
    .container-fluid {
      padding-left: 0;
      padding-right: 0; } }
  .concession-box {
    width: 100%;
    max-width: 580px;
    float: right;
    padding: 60px 100px 60px 50px;
    height: 100%;
    h1 {
      color: #fff;
      font-size: 35px;
      margin-bottom: 25px; }
    p {
      font-size: 17px;
      line-height: 23px;
      color: #fff; } }
  .concession-content {
    padding: 99px 0 30px;
    width: 100%;
    max-width: 790px;
    margin-left: auto;
    margin-right: auto;
    .intro {
      padding: 55px 0 30px;
      text-align: center;
      p {
        font-size: 16px;
        color: #576380; } }
    .image {
      position: relative;
      span {
        position: absolute;
        width: 34px;
        height: 34px;
        z-index: 444; }
      span.bottom {
        bottom: -17px;
        left: -17px;
        background-color: #00CAFF; }
      span.top {
        top: -34px;
        right: -34px;
        background: #545DD6; }
      &.top-square {
        &:before {
          content: '';
          position: absolute;
          top: -14px;
          right: -16px;
          width: 70px;
          height: 70px;
          border: 1px solid #00AAF7;
          z-index: 555; } } } }

  .data-table {
    margin-top: 30px;
    margin-bottom: 50px; } }

.page-heading {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#575dd2+0,7a95ff+50,7a95ff+50 */
  background: #575dd2 /* Old browsers */;
  background: -moz-linear-gradient(left, #575dd2 0%, #7a95ff 50%, #7a95ff 50%) /* FF3.6-15 */;
  background: -webkit-linear-gradient(left, #575dd2 0%,#7a95ff 50%,#7a95ff 50%) /* Chrome10-25,Safari5.1-6 */;
  background: linear-gradient(to right, #575dd2 0%,#7a95ff 50%,#7a95ff 50%) /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#575dd2', endColorstr='#7a95ff',GradientType=1 ) /* IE6-9 */;
  .title {
    padding: 32px 0;
    text-align: center;
    h1 {
      color: #fff;
      font-size: 35px;
      margin-bottom: 0; } } }

.page-title {
  padding: 75px 0;
  .title {
    font-size: 24px;
    color: #001E98;
    position: relative;
    &.online {
      margin-bottom: 46px; }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -15px;
      width: 76px;
      height: 1px;
      background: #00CAFF; }
    &.center {
      text-align: center;
      &:after {
        left: 50%;
        margin-left: -38px; } } }


  p {
    font-size: 17px; } }

.tariff {
  .tariff-content {
    width: 100%;
    max-width: 982px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 95px;
    .intro {
      padding: 80px 0 54px;
      h2 {
        font-size: 24px;
        color: #001E98; }
      p {
        font-size: 16px;
        color: #576380; } }
    .divider {
      height: 2px;
      background: #ccc;
      margin: 40px 0; } }

  .data-table {
    margin-top: 10px; } }

.data-table {
  .table-heading {
    text-align: center;
    padding-bottom: 45px;
    h3 {
      font-size: 24px;
      color: #001E98;
      margin-bottom: 10px; }
    p {
      font-size: 14px;
      color: #001E98;
      margin-bottom: 5px; } }
  .table {
    th {
      background-color: #E9EFFF;
      color: #001E98;
      font-size: 14px;
      font-family: 'Aileron-Bold', sans-serif;
      text-align: center;
      padding: 10px 20px;
      vertical-align: middle;
      border-left: 1px solid #97DDFF;
      border-right: 1px solid #97DDFF;
      border-bottom: 2px solid #00CAFF;
      border-top: 0;
      &.middle {
        border-bottom: 1px solid #97DDFF; }
      &:last-child {
        border-right: 0; }
      &:first-child {
        border-left: 0; } }
    td {
      text-align: center;
      font-size: 14px;
      color: #576380;
      padding: 10px 20px;
      strong {
        font-family: 'Aileron-Bold', sans-serif; } } } }

.claims-enter {
  .claims-enter-heading {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#575dd2+0,7a95ff+50,7a95ff+50 */
    background: #575dd2 /* Old browsers */;
    background: -moz-linear-gradient(left, #575dd2 0%, #7a95ff 50%, #7a95ff 50%) /* FF3.6-15 */;
    background: -webkit-linear-gradient(left, #575dd2 0%,#7a95ff 50%,#7a95ff 50%) /* Chrome10-25,Safari5.1-6 */;
    background: linear-gradient(to right, #575dd2 0%,#7a95ff 50%,#7a95ff 50%) /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#575dd2', endColorstr='#7a95ff',GradientType=1 ) /* IE6-9 */;
    .container-fluid {
      padding-left: 0;
      padding-right: 0; } }
  .claims-enter-box {
    width: 100%;
    max-width: 580px;
    float: right;
    padding: 60px 100px 60px 50px;
    height: 100%;
    h1 {
      color: #fff;
      font-size: 35px;
      margin-bottom: 25px; }
    h2 {
      font-size: 20px;
      color: #fff; }
    p {
      font-size: 17px;
      line-height: 23px;
      color: #fff; } } }

form {
  label {
    font-family: 'Aileron-Bold', sans-serif;
    font-size: 14px;
    color: #576380; }
  .form-control {
    height: 42px;
    font-size: 15px;
    font-family: 'Aileron-Light', sans-serif;
    color: #576380;
    border-color: #E6E6E6; }
  .btn {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00c9fd+0,516bfd+100 */
    background: #00c9fd;
    /* Old browsers */
    background: -moz-linear-gradient(left, #00c9fd 0%, #516bfd 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #00c9fd 0%, #516bfd 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #00c9fd 0%, #516bfd 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00c9fd', endColorstr='#516bfd',GradientType=1 );
    /* IE6-9 */
    color: #fff;
    border-radius: 0;
    width: 156px;
    height: 40px;
    border: 0; } }

.login-form {
  background: #fff;
  padding: 60px 20px;
  form {
    margin-bottom: 40px;
    label {
      font-family: 'Aileron-Bold', sans-serif;
      font-size: 14px;
      color: #576380; }
    .form-control {
      height: 42px;
      font-size: 15px;
      font-family: 'Aileron-Light', sans-serif;
      color: #576380;
      border-color: #E6E6E6; }
    .btn-login {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00c9fd+0,516bfd+100 */
      background: #00c9fd;
      /* Old browsers */
      background: -moz-linear-gradient(left, #00c9fd 0%, #516bfd 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, #00c9fd 0%, #516bfd 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, #00c9fd 0%, #516bfd 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00c9fd', endColorstr='#516bfd',GradientType=1 );
      /* IE6-9 */
      color: #fff;
      border-radius: 0;
      width: 156px;
      height: 40px;
      border: 0; } }

  .forgot-create {
    border-top: 1px solid #E6E6E6;
    padding-top: 15px;
    a {
      font-size: 13px;
      font-family: 'Aileron-Light', sans-serif;
      color: #576380;
      &.btn-create {
        font-family: 'Aileron-Bold', sans-serif;
        text-decoration: underline; } } } }

.claims {
  .claims-content {
    width: 100%;
    max-width: 788px;
    margin-left: auto;
    margin-right: auto; }
  .regulation-content {
    width: 100%;
    max-width: 980px;
    margin-left: auto;
    margin-right: auto; }

  .claims-download {
    padding-bottom: 64px;
    .download-item {
      padding: 20px 50px 32px 107px;
      position: relative;
      border-bottom: 1px solid #00C9FD;
      margin-bottom: 44px;
      .icon {
        position: absolute;
        left: 30px;
        top: 20px; }
      .caption {
        p {
          font-size: 18px;
          color: #576380;
          margin: 0; } } } }
  .form-claims {
    padding: 18px 0 170px;
    .form-group {
      margin-bottom: 29px; }
    textarea {
      height: 151px; }
    .btn {
      margin-top: 14px; } }
  .query-content {
    .page-title {
      padding: 47px 0 43px; } }
  .search-info {
    padding: 13px;
    background: #F0F0F0;
    .search-form {
      .form-inline {
        position: relative;
        .form-control {
          height: 32px;
          border-color: #E6E6E6;
          border-radius: 15px;
          min-width: 380px;
          padding-left: 33px; }
        .icon {
          position: absolute;
          top: 8px;
          left: 11px;
          width: 15px;
          height: 16px;
          background: url('../img/icon-search-md.png') no-repeat;
          z-index: 555; } } }
    .show-date {
      text-align: right;
      .show-items, .date {
        display: inline-block;
        padding: 0 20px; }
      .date {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 8px;
          height: 18px;
          width: 1px;
          background: #ccc; } }
      p {
        margin: 0;
        line-height: 32px; } } } }

.register-table {
  .table-heading {
    text-align: center;
    padding-bottom: 45px;
    h3 {
      font-size: 24px;
      color: #001E98;
      margin-bottom: 10px; }
    p {
      font-size: 14px;
      color: #001E98;
      margin-bottom: 5px; } }
  .table {
    th {
      background-color: #E9EFFF;
      color: #545DAC;
      font-size: 11px;
      font-family: 'Aileron-Bold', sans-serif;
      text-align: center;
      padding: 10px 20px;
      vertical-align: middle;
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
      border-bottom: 0;
      border-top: 1px solid #00CAFF;
      &.middle {
        border-bottom: 1px solid #97DDFF; }
      &:last-child {
        border-right: 0; }
      &:first-child {
        border-left: 0; } }
    td {
      text-align: center;
      font-size: 13px;
      color: #8588A6;
      padding: 5px 20px;
      vertical-align: middle;
      strong {
        font-family: 'Aileron-Bold', sans-serif; } } } }

.contact {
  .page-heading {
    .title {
      padding: 32px 0; } }
  .page-title {
    padding: 47px 0; }
  .contact-content {
    width: 100%;
    max-width: 788px;
    margin-left: auto;
    margin-right: auto; }

  .form-contact {
    padding: 46px 0 170px;
    .form-group {
      margin-bottom: 29px; }
    textarea {
      height: 151px; }
    .btn {
      margin-top: 14px; }
    .image-upload {
      > input {
        display: none; }
      img {
        width: 46px;
        cursor: pointer;
        float: left;
        margin-top: 2px; }
      .text {
        display: inline-block;
        vertical-align: top;
        font-size: 13px;
        span {
          font-family: 'Aileron-Bold', sans-serif;
          font-size: 14px;
          color: #576380; } } } } }
