body {
  font-family: 'Aileron-Light', sans-serif;
  font-size: 14px; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Aileron-Bold', sans-serif; }

a {
  font-family: 'Aileron-Light', sans-serif;
  -webkit-transition: all .3s ease !important;
  -o-transition: all .3s ease !important;
  transition: all .3s ease !important; }

.btn {
  -webkit-transition: all .3s ease !important;
  -o-transition: all .3s ease !important;
  transition: all .3s ease !important; }

.table th, .table td {
  font-family: 'Aileron-Light', sans-serif; }

input[type="text"], input[type="email"], input[type="password"], select, textarea {
  font-family: 'Aileron-Light', sans-serif;
  font-size: 14px; }

ul {
  list-style-type: none;
  padding: 0;
  margin: 0; }

.form-control {
  background: #ffffff;
  border-color: #D3D4D4;
  border-radius: 0;
  color: #4D4D4D;
  height: 30px;
  font-family: 'Aileron-Light', sans-serif;
  font-size: 14px; }

textarea.form-control {
  height: auto; }

.container {
  max-width: 1190px; }

@media (min-width: 576px) {
  .container-boxed {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container-boxed {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container-boxed {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container-boxed {
    max-width: 1140px; } }

.container-boxed {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1230px; }

.gutters-10 {
  margin-right: -10px;
  margin-left: -10px; }

.gutters-10 > .col,
.gutters-10 > [class*=col-] {
  padding-right: 10px;
  padding-left: 10px; }

.gutters-15 {
  margin-right: -15px;
  margin-left: -15px; }

.gutters-15 > .col,
.gutters-15 > [class*=col-] {
  padding-right: 15px;
  padding-left: 15px; }

.header .header-top {
  padding: 34px 0; }
  .header .header-top .widgets ul li {
    display: inline-block;
    height: 28px;
    padding: 0 23px; }
    .header .header-top .widgets ul li:last-child {
      padding-right: 0; }
    .header .header-top .widgets ul li + li {
      border-left: 1px solid #E6E6E6; }
    .header .header-top .widgets ul li a {
      font-size: 15px;
      color: #008CF6;
      text-decoration: none;
      line-height: 29px;
      display: inline-block; }
      .header .header-top .widgets ul li a:hover {
        color: #001E98; }
      .header .header-top .widgets ul li a i {
        display: inline-block;
        width: 29px;
        height: 26px;
        vertical-align: middle;
        margin-right: 3px; }
        .header .header-top .widgets ul li a i.icon-precaution {
          background: url("../img/icon-precaution.png") no-repeat; }
        .header .header-top .widgets ul li a i.icon-doc {
          background: url("../img/icon-doc.png") no-repeat; }
        .header .header-top .widgets ul li a i.icon-email {
          background: url("../img/icon-email.png") no-repeat; }

.navbar-main {
  padding: 0;
  background: #E9EFFF; }
  .navbar-main .navbar-toggler {
    position: absolute;
    top: -50px;
    right: 20px;
    color: #001E98; }
    .navbar-main .navbar-toggler .fa {
      color: #001E98; }
  .navbar-main .nav-item + .nav-item {
    margin-left: 30px; }
  .navbar-main .nav-item .nav-link {
    color: #001E98;
    font-size: 13px;
    text-decoration: none;
    line-height: 51px;
    padding: 0 25px;
    font-family: 'Aileron-Bold', sans-serif;
    position: relative; }
    .navbar-main .nav-item .nav-link:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background: #E9EFFF; }
    .navbar-main .nav-item .nav-link:hover {
      color: #008CF6; }
  .navbar-main .nav-item.active .nav-link {
    color: #008CF6;
    background: #fff; }
    .navbar-main .nav-item.active .nav-link:before {
      background-color: #008CF6; }
  .navbar-main .dropdown-menu {
    background: #001E98;
    padding: 0; }
    .navbar-main .dropdown-menu .dropdown-item {
      border-bottom: 1px solid #2D5AB3;
      padding: 10px 18px;
      color: #fff; }
      .navbar-main .dropdown-menu .dropdown-item:last-child {
        border-bottom: 0; }
      .navbar-main .dropdown-menu .dropdown-item:hover {
        color: #00CAFF;
        background: #001E98; }
      .navbar-main .dropdown-menu .dropdown-item .fa {
        margin-left: 5px;
        line-height: 22px;
        vertical-align: bottom; }

.menu-large {
  position: static !important; }

.megamenu {
  padding: 20px 0px;
  width: 100%;
  -webkit-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  margin: 0; }
  .megamenu ul {
    padding: 0;
    margin: 0; }
    .megamenu ul li {
      list-style: none;
      color: #000000;
      font-family: 'Gotham Rounded Book';
      font-size: 11px; }
      .megamenu ul li.title ~ .title {
        margin-top: 20px; }
      .megamenu ul li a {
        display: block;
        clear: both;
        font-weight: normal;
        font-size: 11px;
        line-height: 20px;
        color: #818181;
        white-space: normal;
        font-family: 'Gotham Rounded Light'; }
        .megamenu ul li a.news {
          text-align: center;
          font-family: 'Gotham Rounded Book';
          font-size: 14px;
          max-width: 200px;
          color: #000000; }
        .megamenu ul li a span {
          display: block;
          padding: 10px 0; }

.dropdown-menu {
  background: #fff;
  border: 0;
  border-radius: 0;
  padding: 20px; }
  .dropdown-menu.show {
    -webkit-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out; }

@media (max-width: 768px) {
  .megamenu {
    margin-left: 0;
    margin-right: 0; }
    .megamenu li {
      margin-bottom: 30px; }
      .megamenu li:last-child {
        margin-bottom: 0; }
  .open .dropdown-menu {
    color: #fff; } }

.fade-carousel {
  position: relative; }
  .fade-carousel .carousel-indicators > li {
    margin: 0 2px;
    background-color: #f39c12;
    border-color: #f39c12;
    opacity: .7; }
    .fade-carousel .carousel-indicators > li.active {
      width: 10px;
      height: 10px;
      opacity: 1; }
  .fade-carousel .carousel-inner .carousel-caption {
    top: 50%;
    left: 50%;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.75);
    -webkit-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    opacity: 0;
    -webkit-transition: 2s all ease-in-out .1s;
    -o-transition: 2s all ease-in-out .1s;
    transition: 2s all ease-in-out .1s; }
    .fade-carousel .carousel-inner .carousel-caption h1 {
      font-size: 200%;
      font-weight: 800;
      margin: 0;
      padding: 0; }
  .fade-carousel .carousel-inner .active .carousel-caption {
    opacity: 1;
    -webkit-transition: 2s all ease-in-out .1s;
    -o-transition: 2s all ease-in-out .1s;
    transition: 2s all ease-in-out .1s; }

.main-slider .carousel-control-next, .main-slider .carousel-control-prev {
  width: 5%; }

.main-slider .carousel-control-next-icon,
.main-slider .carousel-control-prev-icon {
  width: 60px;
  height: 60px;
  background-color: #D9DA5E;
  background-size: 16px 28px;
  display: none; }

.main-slider .carousel-control-next-icon {
  background-image: url("../img/slider/arrow-next.png"); }

.main-slider .carousel-control-prev-icon {
  background-image: url("../img/slider/arrow-prev.png"); }

.main-slider .carousel-item {
  text-align: center; }
  .main-slider .carousel-item img {
    height: auto;
    max-height: 537px;
    width: 100%; }

.main-slider .carousel-caption {
  bottom: 120px;
  text-align: center;
  right: 0;
  left: 0;
  max-width: 608px;
  margin: 0 auto; }
  .main-slider .carousel-caption span {
    display: block;
    font-size: 20px;
    color: #3C3554;
    margin-bottom: 15px; }
  .main-slider .carousel-caption h1 {
    display: inline-block;
    width: 100%;
    font-size: 55px;
    font-family: 'Aileron-Heavy', sans-serif;
    color: #fff;
    text-shadow: 0px 0px 6px black;
    border-bottom: 1px solid #fff;
    padding-bottom: 30px;
    margin-bottom: 0; }
  .main-slider .carousel-caption h2 {
    display: inline-block;
    max-width: 380px;
    font-size: 36px;
    margin-bottom: 30px;
    line-height: 40px;
    color: #000; }
  .main-slider .carousel-caption h3 {
    display: inline-block;
    max-width: 380px;
    font-size: 25px;
    margin-bottom: 30px;
    line-height: 40px;
    color: #fff; }
  .main-slider .carousel-caption .text-caption {
    position: relative;
    padding: 36px 0 30px; }
    .main-slider .carousel-caption .text-caption::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 202px;
      height: 4px;
      background: #fff;
      margin-left: -101px; }
    .main-slider .carousel-caption .text-caption span {
      display: block;
      margin-bottom: 0; }
      .main-slider .carousel-caption .text-caption span.text1 {
        font-size: 20px;
        color: #fff;
        font-family: 'Aileron-Bold', sans-serif; }
  .main-slider .carousel-caption .btn-caption {
    color: #ffffff;
    border: 0;
    background: #615475;
    width: 226px;
    height: 32px;
    font-size: 17px;
    font-family: 'Raleway', sans-serif;
    border-radius: 0;
    line-height: 32px;
    padding: 0 10px;
    margin-top: 22px; }
    .main-slider .carousel-caption .btn-caption:hover {
      border-color: #000;
      background: #000;
      color: #fff; }
  .main-slider .carousel-caption .btn-caption2 {
    color: #ffffff;
    border: 2px solid #fff;
    width: 170px;
    height: 45px;
    font-size: 16px;
    font-family: 'Gotham Rounded Book';
    border-radius: 26px;
    padding: .5rem 50px;
    margin: 0 14px; }
    .main-slider .carousel-caption .btn-caption2:hover {
      border-color: #ffffff;
      background: #ffffff;
      color: #000; }

.main-slider .carousel-indicators li {
  width: 18px;
  height: 18px;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 9px;
  cursor: pointer; }
  .main-slider .carousel-indicators li.active {
    background-color: #fff; }

.section .content {
  max-width: 922px;
  margin-left: auto;
  margin-right: auto; }

.concession-home .concession-content {
  padding-top: 99px;
  padding-bottom: 108px; }
  .concession-home .concession-content h2 {
    font-size: 35px;
    color: #001E98; }
  .concession-home .concession-content p {
    color: #576380;
    font-size: 17px; }
  .concession-home .concession-content .concession-image {
    position: relative; }
    .concession-home .concession-content .concession-image span {
      position: absolute;
      width: 34px;
      height: 34px;
      z-index: 444; }
      .concession-home .concession-content .concession-image span.top {
        top: -34px;
        right: -34px;
        background: #545DD6; }
      .concession-home .concession-content .concession-image span.bottom {
        bottom: -17px;
        left: -17px;
        background-color: #00CAFF; }
    .concession-home .concession-content .concession-image:before {
      content: '';
      position: absolute;
      top: -14px;
      right: -16px;
      width: 70px;
      height: 70px;
      border: 1px solid #00AAF7;
      z-index: 555; }

@media (min-width: 992px) {
  .col-left {
    -ms-flex: 0 0 57%;
    -webkit-box-flex: 0;
    flex: 0 0 57%;
    max-width: 57%; }
  .col-right {
    -ms-flex: 0 0 43%;
    -webkit-box-flex: 0;
    flex: 0 0 43%;
    max-width: 43%; } }

.section-title {
  text-align: center; }
  .section-title h2 {
    font-size: 35px;
    color: #fff; }
  .section-title h3 {
    font-size: 21px;
    color: #fff;
    font-family: 'Aileron-Light', sans-serif;
    margin-bottom: 50px; }

.technique-file {
  background: #6179EC;
  padding: 90px 0 78px; }
  .technique-file .content {
    max-width: 914px;
    border-top: 1px solid #00C9FD;
    padding: 42px 0 0; }
    .technique-file .content p {
      color: #fff;
      font-size: 16px;
      margin-bottom: 5px; }
      .technique-file .content p.blue {
        color: #00CAFF;
        text-align: right; }
    .technique-file .content .feature {
      padding-top: 50px; }
      .technique-file .content .feature .feature-box {
        background: #596CDB;
        border-bottom: 1px solid #00C9FD;
        text-align: center;
        padding: 49px 25px 38px;
        margin-bottom: 32px; }
        .technique-file .content .feature .feature-box .icon {
          height: 70px;
          line-height: 70px;
          vertical-align: bottom;
          display: inline-block; }
        .technique-file .content .feature .feature-box .caption {
          padding-top: 17px; }
          .technique-file .content .feature .feature-box .caption h4 {
            color: #fff;
            font-weight: 900;
            font-size: 16px;
            margin-bottom: 14px; }
          .technique-file .content .feature .feature-box .caption p {
            color: #fff;
            font-size: 16px;
            line-height: 21px; }

.home-news {
  padding: 102px 0 47px; }
  .home-news .content {
    max-width: 918px; }
  .home-news .news-item {
    border: 0;
    border-radius: 0;
    margin-bottom: 30px; }
    .home-news .news-item .card-image {
      border: 3px solid #E6E6E6;
      padding: 8px 8px 29px 8px; }
    .home-news .news-item .card-caption {
      text-align: center; }
      .home-news .news-item .card-caption h4 {
        font-size: 22px;
        color: #001E98; }
        .home-news .news-item .card-caption h4 a {
          color: #001E98;
          font-family: 'Aileron-Bold', sans-serif;
          display: inline-block;
          width: 226px;
          height: 52px;
          background: #fff;
          margin-top: -20px;
          text-decoration: none; }
      .home-news .news-item .card-caption p {
        color: #576380;
        font-size: 14px;
        line-height: 22px; }

.partners {
  padding: 58px 0 45px;
  border-top: 1px solid #E6E6E6; }
  .partners .partners-list {
    display: table;
    width: 100%; }
    .partners .partners-list li {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      width: 27%; }
      .partners .partners-list li:last-child {
        text-align: right;
        width: 19%; }
  .partners .owl-carousel .owl-item {
    text-align: center; }
    .partners .owl-carousel .owl-item img {
      width: auto !important;
      display: inline-block; }

.footer {
  background: #001E98;
  padding: 23px 0; }
  .footer .copy {
    color: #fff;
    line-height: 37px;
    margin-bottom: 0; }
  .footer .footer-nav {
    text-align: right; }
    .footer .footer-nav ul li {
      display: inline-block; }
    .footer .footer-nav .nav-link {
      color: #00CAFF;
      font-size: 14px; }
      .footer .footer-nav .nav-link:hover {
        color: #fff; }

.about .about-heading {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#575dd2+0,7a95ff+50,7a95ff+50 */
  background: #575dd2;
  background: -webkit-gradient(linear, left top, right top, from(#575dd2), color-stop(50%, #7a95ff), color-stop(50%, #7a95ff));
  background: -o-linear-gradient(left, #575dd2 0%, #7a95ff 50%, #7a95ff 50%);
  background: linear-gradient(to right, #575dd2 0%, #7a95ff 50%, #7a95ff 50%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#575dd2', endColorstr='#7a95ff',GradientType=1 ); }
  .about .about-heading .container-fluid {
    padding-left: 0;
    padding-right: 0; }

.about .about-box {
  width: 100%;
  max-width: 580px;
  float: right;
  padding: 60px 100px 60px 60px;
  height: 100%; }
  .about .about-box h1 {
    color: #fff;
    font-size: 35px;
    margin-bottom: 25px; }
  .about .about-box p {
    font-size: 17px;
    line-height: 23px;
    color: #fff; }

.about .about-content {
  width: 100%;
  max-width: 790px;
  margin-left: auto;
  margin-right: auto; }
  .about .about-content .intro {
    padding: 80px 0 54px;
    text-align: center; }
    .about .about-content .intro p {
      font-size: 16px;
      color: #576380; }
  .about .about-content .image {
    position: relative; }
    .about .about-content .image span {
      position: absolute;
      width: 34px;
      height: 34px;
      z-index: 444; }
    .about .about-content .image span.bottom {
      bottom: -17px;
      left: -17px;
      background-color: #00CAFF; }
    .about .about-content .image span.top {
      top: -34px;
      right: -34px;
      background: #545DD6; }
    .about .about-content .image.top-square:before {
      content: '';
      position: absolute;
      top: -14px;
      right: -16px;
      width: 70px;
      height: 70px;
      border: 1px solid #00AAF7;
      z-index: 555; }

.about .data-table {
  margin-top: 72px;
  margin-bottom: 95px; }

.concession .concession-heading {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#575dd2+0,7a95ff+50,7a95ff+50 */
  background: #575dd2;
  background: -webkit-gradient(linear, left top, right top, from(#575dd2), color-stop(50%, #7a95ff), color-stop(50%, #7a95ff));
  background: -o-linear-gradient(left, #575dd2 0%, #7a95ff 50%, #7a95ff 50%);
  background: linear-gradient(to right, #575dd2 0%, #7a95ff 50%, #7a95ff 50%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#575dd2', endColorstr='#7a95ff',GradientType=1 ); }
  .concession .concession-heading .container-fluid {
    padding-left: 0;
    padding-right: 0; }

.concession .concession-box {
  width: 100%;
  max-width: 580px;
  float: right;
  padding: 60px 100px 60px 50px;
  height: 100%; }
  .concession .concession-box h1 {
    color: #fff;
    font-size: 35px;
    margin-bottom: 25px; }
  .concession .concession-box p {
    font-size: 17px;
    line-height: 23px;
    color: #fff; }

.concession .concession-content {
  padding: 99px 0 30px;
  width: 100%;
  max-width: 790px;
  margin-left: auto;
  margin-right: auto; }
  .concession .concession-content .intro {
    padding: 55px 0 30px;
    text-align: center; }
    .concession .concession-content .intro p {
      font-size: 16px;
      color: #576380; }
  .concession .concession-content .image {
    position: relative; }
    .concession .concession-content .image span {
      position: absolute;
      width: 34px;
      height: 34px;
      z-index: 444; }
    .concession .concession-content .image span.bottom {
      bottom: -17px;
      left: -17px;
      background-color: #00CAFF; }
    .concession .concession-content .image span.top {
      top: -34px;
      right: -34px;
      background: #545DD6; }
    .concession .concession-content .image.top-square:before {
      content: '';
      position: absolute;
      top: -14px;
      right: -16px;
      width: 70px;
      height: 70px;
      border: 1px solid #00AAF7;
      z-index: 555; }

.concession .data-table {
  margin-top: 30px;
  margin-bottom: 50px; }

.page-heading {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#575dd2+0,7a95ff+50,7a95ff+50 */
  background: #575dd2;
  background: -webkit-gradient(linear, left top, right top, from(#575dd2), color-stop(50%, #7a95ff), color-stop(50%, #7a95ff));
  background: -o-linear-gradient(left, #575dd2 0%, #7a95ff 50%, #7a95ff 50%);
  background: linear-gradient(to right, #575dd2 0%, #7a95ff 50%, #7a95ff 50%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#575dd2', endColorstr='#7a95ff',GradientType=1 ); }
  .page-heading .title {
    padding: 32px 0;
    text-align: center; }
    .page-heading .title h1 {
      color: #fff;
      font-size: 35px;
      margin-bottom: 0; }

.page-title {
  padding: 75px 0; }
  .page-title .title {
    font-size: 24px;
    color: #001E98;
    position: relative; }
    .page-title .title.online {
      margin-bottom: 46px; }
    .page-title .title:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -15px;
      width: 76px;
      height: 1px;
      background: #00CAFF; }
    .page-title .title.center {
      text-align: center; }
      .page-title .title.center:after {
        left: 50%;
        margin-left: -38px; }
  .page-title p {
    font-size: 17px; }

.tariff .tariff-content {
  width: 100%;
  max-width: 982px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 95px; }
  .tariff .tariff-content .intro {
    padding: 80px 0 54px; }
    .tariff .tariff-content .intro h2 {
      font-size: 24px;
      color: #001E98; }
    .tariff .tariff-content .intro p {
      font-size: 16px;
      color: #576380; }
  .tariff .tariff-content .divider {
    height: 2px;
    background: #ccc;
    margin: 40px 0; }

.tariff .data-table {
  margin-top: 10px; }

.data-table .table-heading {
  text-align: center;
  padding-bottom: 45px; }
  .data-table .table-heading h3 {
    font-size: 24px;
    color: #001E98;
    margin-bottom: 10px; }
  .data-table .table-heading p {
    font-size: 14px;
    color: #001E98;
    margin-bottom: 5px; }

.data-table .table th {
  background-color: #E9EFFF;
  color: #001E98;
  font-size: 14px;
  font-family: 'Aileron-Bold', sans-serif;
  text-align: center;
  padding: 10px 20px;
  vertical-align: middle;
  border-left: 1px solid #97DDFF;
  border-right: 1px solid #97DDFF;
  border-bottom: 2px solid #00CAFF;
  border-top: 0; }
  .data-table .table th.middle {
    border-bottom: 1px solid #97DDFF; }
  .data-table .table th:last-child {
    border-right: 0; }
  .data-table .table th:first-child {
    border-left: 0; }

.data-table .table td {
  text-align: center;
  font-size: 14px;
  color: #576380;
  padding: 10px 20px; }
  .data-table .table td strong {
    font-family: 'Aileron-Bold', sans-serif; }

.claims-enter .claims-enter-heading {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#575dd2+0,7a95ff+50,7a95ff+50 */
  background: #575dd2;
  background: -webkit-gradient(linear, left top, right top, from(#575dd2), color-stop(50%, #7a95ff), color-stop(50%, #7a95ff));
  background: -o-linear-gradient(left, #575dd2 0%, #7a95ff 50%, #7a95ff 50%);
  background: linear-gradient(to right, #575dd2 0%, #7a95ff 50%, #7a95ff 50%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#575dd2', endColorstr='#7a95ff',GradientType=1 ); }
  .claims-enter .claims-enter-heading .container-fluid {
    padding-left: 0;
    padding-right: 0; }

.claims-enter .claims-enter-box {
  width: 100%;
  max-width: 580px;
  float: right;
  padding: 60px 100px 60px 50px;
  height: 100%; }
  .claims-enter .claims-enter-box h1 {
    color: #fff;
    font-size: 35px;
    margin-bottom: 25px; }
  .claims-enter .claims-enter-box h2 {
    font-size: 20px;
    color: #fff; }
  .claims-enter .claims-enter-box p {
    font-size: 17px;
    line-height: 23px;
    color: #fff; }

form label {
  font-family: 'Aileron-Bold', sans-serif;
  font-size: 14px;
  color: #576380; }

form .form-control {
  height: 42px;
  font-size: 15px;
  font-family: 'Aileron-Light', sans-serif;
  color: #576380;
  border-color: #E6E6E6; }

form .btn {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00c9fd+0,516bfd+100 */
  background: #00c9fd;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#00c9fd), to(#516bfd));
  background: -o-linear-gradient(left, #00c9fd 0%, #516bfd 100%);
  background: linear-gradient(to right, #00c9fd 0%, #516bfd 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00c9fd', endColorstr='#516bfd',GradientType=1 );
  /* IE6-9 */
  color: #fff;
  border-radius: 0;
  width: 156px;
  height: 40px;
  border: 0; }

.login-form {
  background: #fff;
  padding: 60px 20px; }
  .login-form form {
    margin-bottom: 40px; }
    .login-form form label {
      font-family: 'Aileron-Bold', sans-serif;
      font-size: 14px;
      color: #576380; }
    .login-form form .form-control {
      height: 42px;
      font-size: 15px;
      font-family: 'Aileron-Light', sans-serif;
      color: #576380;
      border-color: #E6E6E6; }
    .login-form form .btn-login {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00c9fd+0,516bfd+100 */
      background: #00c9fd;
      /* Old browsers */
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      background: -webkit-gradient(linear, left top, right top, from(#00c9fd), to(#516bfd));
      background: -o-linear-gradient(left, #00c9fd 0%, #516bfd 100%);
      background: linear-gradient(to right, #00c9fd 0%, #516bfd 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00c9fd', endColorstr='#516bfd',GradientType=1 );
      /* IE6-9 */
      color: #fff;
      border-radius: 0;
      width: 156px;
      height: 40px;
      border: 0; }
  .login-form .forgot-create {
    border-top: 1px solid #E6E6E6;
    padding-top: 15px; }
    .login-form .forgot-create a {
      font-size: 13px;
      font-family: 'Aileron-Light', sans-serif;
      color: #576380; }
      .login-form .forgot-create a.btn-create {
        font-family: 'Aileron-Bold', sans-serif;
        text-decoration: underline; }

.claims .claims-content {
  width: 100%;
  max-width: 788px;
  margin-left: auto;
  margin-right: auto; }

.claims .regulation-content {
  width: 100%;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto; }

.claims .claims-download {
  padding-bottom: 64px; }
  .claims .claims-download .download-item {
    padding: 20px 50px 32px 107px;
    position: relative;
    border-bottom: 1px solid #00C9FD;
    margin-bottom: 44px; }
    .claims .claims-download .download-item .icon {
      position: absolute;
      left: 30px;
      top: 20px; }
    .claims .claims-download .download-item .caption p {
      font-size: 18px;
      color: #576380;
      margin: 0; }

.claims .form-claims {
  padding: 18px 0 170px; }
  .claims .form-claims .form-group {
    margin-bottom: 29px; }
  .claims .form-claims textarea {
    height: 151px; }
  .claims .form-claims .btn {
    margin-top: 14px; }

.claims .query-content .page-title {
  padding: 47px 0 43px; }

.claims .search-info {
  padding: 13px;
  background: #F0F0F0; }
  .claims .search-info .search-form .form-inline {
    position: relative; }
    .claims .search-info .search-form .form-inline .form-control {
      height: 32px;
      border-color: #E6E6E6;
      border-radius: 15px;
      min-width: 380px;
      padding-left: 33px; }
    .claims .search-info .search-form .form-inline .icon {
      position: absolute;
      top: 8px;
      left: 11px;
      width: 15px;
      height: 16px;
      background: url("../img/icon-search-md.png") no-repeat;
      z-index: 555; }
  .claims .search-info .show-date {
    text-align: right; }
    .claims .search-info .show-date .show-items, .claims .search-info .show-date .date {
      display: inline-block;
      padding: 0 20px; }
    .claims .search-info .show-date .date {
      position: relative; }
      .claims .search-info .show-date .date:before {
        content: '';
        position: absolute;
        left: 0;
        top: 8px;
        height: 18px;
        width: 1px;
        background: #ccc; }
    .claims .search-info .show-date p {
      margin: 0;
      line-height: 32px; }

.register-table .table-heading {
  text-align: center;
  padding-bottom: 45px; }
  .register-table .table-heading h3 {
    font-size: 24px;
    color: #001E98;
    margin-bottom: 10px; }
  .register-table .table-heading p {
    font-size: 14px;
    color: #001E98;
    margin-bottom: 5px; }

.register-table .table th {
  background-color: #E9EFFF;
  color: #545DAC;
  font-size: 11px;
  font-family: 'Aileron-Bold', sans-serif;
  text-align: center;
  padding: 10px 20px;
  vertical-align: middle;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 0;
  border-top: 1px solid #00CAFF; }
  .register-table .table th.middle {
    border-bottom: 1px solid #97DDFF; }
  .register-table .table th:last-child {
    border-right: 0; }
  .register-table .table th:first-child {
    border-left: 0; }

.register-table .table td {
  text-align: center;
  font-size: 13px;
  color: #8588A6;
  padding: 5px 20px;
  vertical-align: middle; }
  .register-table .table td strong {
    font-family: 'Aileron-Bold', sans-serif; }

.contact .page-heading .title {
  padding: 32px 0; }

.contact .page-title {
  padding: 47px 0; }

.contact .contact-content {
  width: 100%;
  max-width: 788px;
  margin-left: auto;
  margin-right: auto; }

.contact .form-contact {
  padding: 46px 0 170px; }
  .contact .form-contact .form-group {
    margin-bottom: 29px; }
  .contact .form-contact textarea {
    height: 151px; }
  .contact .form-contact .btn {
    margin-top: 14px; }
  .contact .form-contact .image-upload > input {
    display: none; }
  .contact .form-contact .image-upload img {
    width: 46px;
    cursor: pointer;
    float: left;
    margin-top: 2px; }
  .contact .form-contact .image-upload .text {
    display: inline-block;
    vertical-align: top;
    font-size: 13px; }
    .contact .form-contact .image-upload .text span {
      font-family: 'Aileron-Bold', sans-serif;
      font-size: 14px;
      color: #576380; }
